var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "p-3",
    attrs: {
      "id": "pdfContent"
    }
  }, [_c('v-row', {
    staticClass: "dashed-border pb-3"
  }, [_c('b-col', {
    staticClass: "box-border info-box d-flex align-items-center"
  }, [_c('h5', [_vm._v("TAX INVOICE")])]), _c('b-col', {
    staticClass: "box-border info-box"
  }, [_c('p', [_vm._v("Order Id: " + _vm._s(_vm.invoiceData ? _vm.invoiceData.orderId : ""))]), _c('p', [_vm._v("Order Date: " + _vm._s(_vm.createdDate))])]), _c('b-col', {
    staticClass: "info-box"
  }, [_c('p', [_vm._v("Payment Mode: COD")])])], 1), _c('v-row', {
    staticClass: "dashed-border"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mt-3 seller-box"
  }, [_c('b-img', {
    staticClass: "seller-img",
    attrs: {
      "src": _vm.sellerInfo && _vm.sellerInfo.organization && _vm.sellerInfo.organization.logo ? _vm.sellerInfo.organization.logo.url : '',
      "alt": ""
    }
  }), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.organization ? _vm.sellerInfo.organization.name : "") + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.city : "") + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.state : "") + " - " + _vm._s(_vm.sellerInfo && _vm.sellerInfo.office && _vm.sellerInfo.office.address ? _vm.sellerInfo.office.address.zip : "") + " ")]), _c('p', [_vm._v("Phone: " + _vm._s(_vm.sellerPhone))])], 1)])], 1), _c('b-row', {
    staticClass: "dashed-border"
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('h6', [_vm._v("Item")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('h6', [_vm._v("Qty")])]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('h6', [_vm._v("MRP")])]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('h6', [_vm._v("Base Price")])]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('h6', [_vm._v("Tax")])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('h6', {
    staticClass: "text-right"
  }, [_vm._v("Amount(₹)")])])], 1), _c('b-row', {
    staticClass: "dashed-border"
  }, _vm._l(_vm.invoiceData.ItemsByProvider, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "d-flex w-100"
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('p', [_vm._v(_vm._s(item.name))])]), _c('b-col', {
      attrs: {
        "md": "1"
      }
    }, [_c('p', [_vm._v(_vm._s(item.qty))])]), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('p', [_vm._v(_vm._s(item.unitPrice))])]), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('p', [_vm._v(_vm._s(item.price))])]), _c('b-col', {
      attrs: {
        "md": "1"
      }
    }, [_c('p', [_vm._v(_vm._s(item.totalTaxes))])]), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('p', {
      staticClass: "text-right pr-2"
    }, [_vm._v(_vm._s(item.finalPrice))])])], 1);
  }), 0), _c('b-row', {
    staticClass: "dashed-border"
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('h6', [_vm._v("Total")])]), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_vm.sellerInfo.organization.name !== 'Instore Seller' ? _c('h6', [_vm._v(" " + _vm._s(Object.keys(_vm.invoiceData.ItemsByProvider).length) + " ")]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_vm.sellerInfo.organization.name !== 'Instore Seller' ? _c('h6', [_vm._v(" " + _vm._s(_vm.totalTaxAmount) + " ")]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('h6', {
    staticClass: "text-right pr-2"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData ? _vm.invoiceData.orderAmount : "") + " ")])])], 1), _c('v-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mt-4 dashed-border"
  }, [_c('p', [_vm._v("Thank You for shoping with us")]), _c('p', [_vm._v("Vist us again")])])])], 1)], 1), _c('v-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.downloadInvoicePdf
    }
  }, [_c('span', {
    staticClass: "pr-3 pl-3"
  }, [_vm._v("Download Invoice")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }