<template>
  <v-container>
    <div id="pdfContent" class="p-3">
      <v-row class="dashed-border pb-3">
        <b-col class="box-border info-box d-flex align-items-center">
          <h5>TAX INVOICE</h5>
        </b-col>
        <b-col class="box-border info-box">
          <p>Order Id: {{ invoiceData ? invoiceData.orderId : "" }}</p>
          <p>Order Date: {{ createdDate }}</p>
        </b-col>
        <!-- <b-col class="box-border info-box">
          <p>Invoice No: FABNE26646416</p>
          <p>Invoice Date: 20-10-22 02:42 pm</p>
        </b-col> -->
        <b-col class="info-box">
          <!-- <p>GSTIN: 29BKDPM1684168</p> -->
          <p>Payment Mode: COD</p>
        </b-col>
      </v-row>
      <v-row class="dashed-border">
        <b-col md="12">
          <div class="d-flex flex-column align-items-center mt-3 seller-box">
            <b-img
              :src="
                sellerInfo &&
                sellerInfo.organization &&
                sellerInfo.organization.logo
                  ? sellerInfo.organization.logo.url
                  : ''
              "
              class="seller-img"
              alt=""
            />
            <h5 class="mb-2">
              {{
                sellerInfo && sellerInfo.organization
                  ? sellerInfo.organization.name
                  : ""
              }}
            </h5>
            <p>
              {{
                sellerInfo && sellerInfo.office && sellerInfo.office.address
                  ? sellerInfo.office.address.city
                  : ""
              }}
            </p>
            <p>
              {{
                sellerInfo && sellerInfo.office && sellerInfo.office.address
                  ? sellerInfo.office.address.state
                  : ""
              }}
              -
              {{
                sellerInfo && sellerInfo.office && sellerInfo.office.address
                  ? sellerInfo.office.address.zip
                  : ""
              }}
            </p>
            <p>Phone: {{ sellerPhone }}</p>
          </div></b-col
        >
      </v-row>
      <b-row class="dashed-border">
        <b-col md="3"> <h6>Item</h6></b-col>
        <b-col md="1"> <h6>Qty</h6> </b-col>
        <b-col md="2"> <h6>MRP</h6> </b-col>
        <b-col md="2"> <h6>Base Price</h6> </b-col>
        <b-col md="1"> <h6>Tax</h6> </b-col>
        <b-col md="3"> <h6 class="text-right">Amount(₹)</h6> </b-col>
      </b-row>
      <b-row class="dashed-border">
        <div
          class="d-flex w-100"
          v-for="item in invoiceData.ItemsByProvider"
          :key="item._id"
        >
          <b-col md="3">
            <p>{{ item.name }}</p></b-col
          >
          <b-col md="1">
            <p>{{ item.qty }}</p>
          </b-col>
          <b-col md="2">
            <p>{{ item.unitPrice }}</p>
          </b-col>
          <b-col md="2">
            <p>{{ item.price }}</p>
          </b-col>
          <b-col md="1">
            <p>{{ item.totalTaxes }}</p>
          </b-col>
          <b-col md="3">
            <p class="text-right pr-2">{{ item.finalPrice }}</p>
          </b-col>
        </div>
      </b-row>
      <b-row class="dashed-border">
        <b-col md="3"> <h6>Total</h6></b-col>
        <b-col md="5">
          <h6 v-if="sellerInfo.organization.name !== 'Instore Seller'">
            {{ Object.keys(invoiceData.ItemsByProvider).length }}
          </h6>
        </b-col>
        <b-col md="1">
          <h6 v-if="sellerInfo.organization.name !== 'Instore Seller'">
            {{ totalTaxAmount }}
          </h6>
        </b-col>
        <b-col md="3">
          <h6 class="text-right pr-2">
            {{ invoiceData ? invoiceData.orderAmount : "" }}
          </h6>
        </b-col>
      </b-row>
      <!-- <b-row class="dashed-border d-flex justify-content-end">
        <b-col md="1"> <p>CGST</p> </b-col>
        <b-col md="3">
          <p class="text-right pr-2">15</p>
        </b-col>
      </b-row> -->
      <v-row>
        <b-col md="12"
          ><div
            class="d-flex flex-column align-items-center mt-4 dashed-border"
          >
            <!-- <p>You have saved Rs.200+ on this order + eligible Cash Back</p> -->
            <p>Thank You for shoping with us</p>
            <p>Vist us again</p>
          </div>
        </b-col>
      </v-row>
    </div>
    <v-row>
      <b-col md="12">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary"
            @click="downloadInvoicePdf"
          >
            <span class="pr-3 pl-3">Download Invoice</span>
          </button>
        </div>
      </b-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      invoiceData: (state) => state.profile.invoiceData,
      sellerInfo: (state) => state.profile.sellerInfo,
      orderDetailsData: (state) => state.profile.orderDetailsData,
    }),
    createdDate() {
      return moment(this.orderDetailsData.createdAt).format(
        "MMMM Do YYYY, hh:mm a"
      );
    },
    sellerPhone() {
      const number = Array.from(
        new Set(this.sellerInfo.office?.phone.map((each) => each.number))
      );
      return number[0];
    },
    totalTaxAmount() {
      let taxes = this.invoiceData.ItemsByProvider.map(
        (each) => each.totalTaxes
      );
      let tax = 0;
      for (let i = 0; i < taxes.length; i++) {
        tax = tax + taxes[i];
      }
      return tax;
    },
  },
  methods: {
    downloadInvoicePdf() {
      const pdfDoc = document.querySelector("#pdfContent");
      html2canvas(pdfDoc, {
        allowTaint: true,
        useCORS: true,
        scale: 1,
        width: 1165,
        height: 900,
      }).then((canvas) => {
        var imgData = canvas.toDataURL("image/png");
        var doc = new jsPDF("p");
        doc.addImage(imgData, "PNG", 10, 10, 190, 190);
        doc.save("output.pdf");
      });
    },
  },
};
</script>

<style scoped>
.dashed-border {
  border-bottom: 2px dashed #e3e3e3;
}
.box-border {
  border-right: 2px dashed #e3e3e3;
}
.info-box p,
h5 {
  margin-bottom: 0px;
}
.seller-box p {
  margin-bottom: 0.3rem;
}
.seller-img {
  widows: 50px;
  height: 50px;
  border-radius: 25px;
}
</style>
